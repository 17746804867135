<!-- Complaint -->
<template>
  <div class="complaint">
    <div class="content">
      <div class="header">请选择投诉原因</div>
      <div class="list">
        <RadioGroup v-model="checked">
          <CellGroup inset>
            <Cell
              :title="item.name"
              clickable
              @click="checked = item.id"
              v-for="item in list"
              :key="item.id"
            >
              <template #right-icon>
                <Radio :name="item.id" />
              </template>
            </Cell>
          </CellGroup>
        </RadioGroup>
      </div>
    </div>

    <Button type="info" @click="handleNext">下一步</Button>
  </div>
</template>

<script>
import { Cell, CellGroup, RadioGroup, Radio, Button } from 'vant'
export default {
  components: { Cell, CellGroup, RadioGroup, Radio, Button },
  data() {
    return {
      checked: 1,
      list: [
        {
          id: 1,
          name: '虚假活动，活动不真实'
        }, {
          id: 2,
          name: '报名后无法联系商家'
        }, {
          id: 3,
          name: '商家拒绝退款'
        }, {
          id: 4,
          name: '页面内涵政治敏感、色情低俗、欺诈、违法、暴力'
        }, {
          id: 5,
          name: '页面内涵造谣、传谣'
        }, {
          id: 6,
          name: '页面内涵侵权、抄袭'
        }, {
          id: 7,
          name: '页面内涵诱导分享'
        }, {
          id: 8,
          name: '其他'
        },
      ]
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleNext() {
      this.$router.push({
        path: '/ComplaintDetail',
        query: {
          event_id: this.$route.query.event_id,
          checked: this.checked
        }
      })
    }
  }
}
</script>
<style lang="scss">
.complaint {
  height: 100vh;
  background: #f5f5f5;
  .header {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
  }
  .van-cell-group--inset {
    margin: 0;
  }
  .van-cell {
    padding: 14px 25px;
  }

  .van-button {
    display: block;
    margin: 30px auto;
    width: 92%;
  }
}
</style>